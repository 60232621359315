







































































import router from '@/router'
import { Component, Vue } from 'vue-property-decorator'
import store from '@/store'
import { LoginData } from '@/models/interface/LoginData'

@Component
export default class Login extends Vue {
  show = true
  formError = ''
  formData: LoginData = {
    email: '',
    password: ''
  }
  isLoading = false
  checkingAuth = true
  redirectUrl = ''

  async created() {
    this.redirectUrl = this.$route.params.redirectUrl ? this.$route.params.redirectUrl : '/'

    await this.$store.dispatch('auth/checkIsAuthenticated')
    if (store.state.auth.isAuthenticated) {
      this.redirectOnSuccessfulAuthentication()
    }
    this.checkingAuth = false
  }

  get isAuthenticated() {
    return this.$store.state.auth.isAuthenticated
  }
  get currentUser() {
    return this.$store.state.auth.currentUser
  }

  resetForm() {
    this.formError = ''
    this.formData.email = ''
    this.formData.password = ''

    this.show = false
    this.$nextTick(() => {
      this.show = true
    })
  }
  submitForm() {
    this.login(this.formData)
  }
  async login(data: LoginData) {
    try {
      this.isLoading = true
      this.formError = ''
      await this.$store.dispatch('auth/login', data)
      if (this.$store.state.auth.isAuthenticated) {
        this.redirectOnSuccessfulAuthentication()
      }
    } catch (error) {
      console.log(error)
      this.formError = error.response.data.error
    } finally {
      this.isLoading = false
    }
  }

  redirectOnSuccessfulAuthentication() {
    if (this.$store.state.product.currentProduct) {
      router.push(this.redirectUrl)
    } else {
      router.push({ name: 'CreateOrJoinProduct' })
    }
  }
}
